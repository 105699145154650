const routes = [
  {
    path: '',
    name: 'systemSetting',
    meta: {
      title: '系统设置'
    }
  },
  {
    path: 'systemSetting/changePassword',
    name: 'changePassword',
    component: () => import(/* webpackChunkName: "changePassword" */ '@/views/Module_SystemSettingManage/Page_ChangePasswordManage'),
    meta: {
      title: '修改密码'
    }
  },
  {
    path: 'systemSetting/addDoctor',
    name: 'addDoctor',
    component: () => import(/* webpackChunkName: "addDoctor" */ '@/views/Module_SystemSettingManage/Page_AddDoctorManage')
  },
  {
    path: 'systemSetting/OperationLog',
    name: 'operationLog',
    component: () => import(/* webpackChunkName: "operationLog" */ '@/views/Module_SystemSettingManage/Page_OperationLogManage'),
    meta: {
      title: '操作日志'
    }
  },
  {
    path: 'systemSetting/verifyDataConfig',
    name: 'verifyDataConfig',
    component: () => import(/* webpackChunkName: "verifyDataConfig" */ '@/views/Module_SystemSettingManage/Page_VerifyDataConfig')
  },
  {
    path: 'systemSetting/PermissionManage',
    name: 'permissionManage',
    component: () => import(/* webpackChunkName: "PermissionManage" */ '@/views/Module_SystemSettingManage/Page_PermissionManage'),
    meta: {
      title: '权限管理'
    }
  },
  {
    path: 'systemSetting/HospitalManage',
    name: 'hospitalManage',
    component: () => import(/* webpackChunkName: "HospitalManage" */ '@/views/Module_SystemSettingManage/Page_HospitalManage'),
    meta: {
      title: '医院管理'
    }
  },
  {
    path: 'systemSetting/deviceManage',
    name: 'deviceManage',
    component: () => import(/* webpackChunkName: "deviceManage" */ '@/views/Module_SystemSettingManage/Page_DeviceManage'),
    meta: {
      title: '设备管理'
    }
  }
]

export default routes
