const routes = [
  {
    path: 'statistics',
    name: 'Statistics',
    component: () => import(/* webpackChunkName: "Statistics" */ '@/views/Module_DataStatistics'),
    meta: {
      title: '程控信息审核'
    }
  }
]

export default routes
