import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import SlsTracker from '@aliyun-sls/web-track-browser'
import 'element-ui/lib/theme-chalk/index.css'
import '@/style/index.scss'
import to from 'await-to-js'
import '@/style/iconfont.css'

import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import { trackerConfig } from '@/libs/utils/ali-tracker-config'

Vue.prototype.$to = to
Vue.config.productionTip = false
Vue.use(Element)
Vue.use(VXETable)
Vue.prototype.$store = store

const _send = (event = '', title = '', desc = '', params = {}) => {
  const tracker = new SlsTracker(trackerConfig)
  tracker.send({
    event,
    title,
    description: desc,
    timestamp: new Date().getTime(),
    ...params
  })
  console.log('123321')
}

Vue.prototype._send = _send

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
