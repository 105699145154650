export const trackerConfig = {
  host: 'cn-beijing.log.aliyuncs.com',
  project: 'sm-front-end-log',
  logstore: process.env.NODE_ENV === 'production' ? 'web-followup-logstore' : 'web-followup-logstore-test',
  time: 0,
  source: 'web-followup',
  tags: {
    env: process.env.NODE_ENV === 'production' ? 'production' : 'test'
  }
}
