const routes = [
  {
    path: 'auditingPatient',
    name: 'auditingPatient',
    component: () => import(/* webpackChunkName: "auditingPatient" */ '@/views/Module_AuditingPatient'),
    meta: {
      title: ''
    }
  },
  {
    path: 'reviewPatientInfo',
    name: 'reviewPatientInfo',
    component: () => import(/* webpackChunkName: "reviewPatientInfo" */ '@/views/Module_AuditingPatient/reviewPatientInfo'),
    meta: {
      title: '待审核患者 / 审核患者信息'
    }
  }
]

export default routes
