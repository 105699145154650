const routes = [
  {
    path: 'proofread',
    name: 'Proofread',
    component: () => import(/* webpackChunkName: "Proofread" */ '@/views/Module_PendingTasksManage/Page_Proofread'),
    meta: {
      title: '程控信息审核'
    }
  }
]

export default routes
