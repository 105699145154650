const routes = [
  {
    path: 'pendingTask',
    name: 'pendingTask',
    component: () => import(/* webpackChunkName: "pendingTask" */ '@/views/Module_PendingTasksManage'),
    meta: {
      title: ''
    }
  }
]

export default routes
