const routes = [
  {
    path: 'enterPatient',
    name: 'EnterPatient',
    component: () => import(/* webpackChunkName: "EnterPatient" */ '@/views/Module_PatientManage/'),
    meta: {
      title: '患者管理'
    }
  },
  {
    path: 'addPatient',
    name: 'AddPatient',
    component: () => import(/* webpackChunkName: "AddPatient" */ '@/views/Module_PatientManage/addPatient'),
    meta: {
      title: '添加患者'
    }
  },
  {
    path: 'followupDetail',
    name: 'followupDetail',
    component: () => import(/* webpackChunkName: "followupDetail" */ '@/views/Module_PatientManage/Page_FollowupDetail'),
    meta: {
      title: '随访管理 / 随访详情'
    }
  },
  {
    path: 'patientFiles',
    name: 'patientFiles',
    component: () => import(/* webpackChunkName: "patientFiles" */ '@/views/Module_PatientManage/Page_PatientFiles'),
    meta: {
      title: '随访管理 / 患者档案'
    }
  },
  {
    path: 'completeInfo',
    name: 'completeInfo',
    component: () => import(/* webpackChunkName: "patientFiles" */ '@/views/Module_PatientManage/Page_PatientFiles/complete_info'),
    meta: {
      title: ''
    }
  },
  // 新建随访
  {
    path: 'createFollowup',
    name: 'createFollowup',
    component: () => import(/* webpackChunkName: "createFollowup" */ '@/views/Module_PatientManage/Page_CreateFollowup')
  },
  // 编辑随访
  {
    path: 'editFollowup',
    name: 'editFollowup',
    component: () => import(/* webpackChunkName: "editFollowup" */ '@/views/Module_PatientManage/Page_EditFollowup')
  }
]

export default routes
