import Vue from 'vue'
import VueRouter from 'vue-router'
// import Layout from '../layout'
import auditingPatient from './module/auditingPatient_module.js'
import pendingtask from './module/pendingtasks_module'
import patient from './module/patient_module'
import proofread from './module/proofread_module'
import statistics from './module/statistics_module'
import systemsetting from './module/systemsetting_module'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: () => import(/* webpackChunkName: "Layout" */ '@/layout'),
    redirect: '/enterPatient',
    children: [
      {
        path: 'information',
        name: 'Information',
        component: () => import(/* webpackChunkName: "Information" */ '@/views/Information/'),
        meta: {
          title: '个人信息'
        }
      },
      ...auditingPatient,
      ...pendingtask,
      ...patient,
      ...proofread,
      ...statistics,
      ...systemsetting
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '@/views/Login/index')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.replace = function replace (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
router.beforeEach((to, from, next) => {
  const token = window.sessionStorage.getItem('Authorization')
  if (to.path !== '/login' && !token) {
    next({
      path: '/login'
    })
  } else { next() }
  if (to.path === '/') {
    sessionStorage.setItem('LoginStatus', true)
    // 菜单展示优先级，患者管理->待审核患者->待处理任务->数据统计->系统设置
    var authList
    if (sessionStorage.getItem('auth')) {
      authList = JSON.parse(sessionStorage.getItem('auth')).perms
      console.log('123:', authList)
    }
    if (authList) {
      if (authList.indexOf('patient-mgr:access') !== -1) {
        next({
          path: '/enterPatient'
        })
      } else if (authList.indexOf('patient-mgr:access') === -1 && authList.indexOf('audit-patient:access') !== -1) {
        next({
          path: '/auditingPatient'
        })
      } else if (authList.indexOf('patient-mgr:access') === -1 && authList.indexOf('audit-patient:access') === -1 && authList.indexOf('verify-task:access') !== -1) {
        next({
          path: '/pendingTask'
        })
      } else if (authList.indexOf('patient-mgr:access') === -1 && authList.indexOf('audit-patient:access') === -1 && authList.indexOf('verify-task:access') === -1 && authList.indexOf('statistics:access') !== -1) {
        next({
          path: '/statistics'
        })
      } else if (authList.indexOf('patient-mgr:access') === -1 && authList.indexOf('audit-patient:access') === -1 && authList.indexOf('verify-task:access') === -1 && authList.indexOf('statistics:access') === -1 && authList.indexOf('system-settings:access') !== -1) {
        if (authList.indexOf('data-verify-settings:access') !== -1) {
          next({
            path: '/systemSetting/verifyDataConfig'
          })
        } else if (authList.indexOf('data-verify-settings:access' === -1) && authList.indexOf('hospital-mgr:access') !== -1) {
          next({
            path: '/systemSetting/HospitalManage'
          })
        } else if (authList.indexOf('data-verify-settings:access' === -1) && authList.indexOf('hospital-mgr:access') === -1 && authList.indexOf('device-mgr:access') !== -1) {
          next({
            path: '/systemSetting/deviceManage'
          })
        } else if (authList.indexOf('data-verify-settings:access' === -1) && authList.indexOf('hospital-mgr:access') === -1 && authList.indexOf('device-mgr:access') === -1 && authList.indexOf('permission-mgr:access') !== -1) {
          next({
            path: '/systemSetting/PermissionManage'
          })
        } else if (authList.indexOf('data-verify-settings:access' === -1) && authList.indexOf('hospital-mgr:access') === -1 && authList.indexOf('device-mgr:access') === -1 && authList.indexOf('permission-mgr:access') === -1 && authList.indexOf('operation-log:access') !== -1) {
          next({
            path: '/systemSetting/operationLog'
          })
        } else if (authList.indexOf('data-verify-settings:access' === -1) && authList.indexOf('hospital-mgr:access') === -1 && authList.indexOf('device-mgr:access') === -1 && authList.indexOf('operation-log:access') === -1 && authList.indexOf('permission-mgr:access') === -1 && authList.indexOf('password-change:access') !== -1) {
          next({
            path: '/systemSetting/changePassword'
          })
        }
      }
    }
  } else { next() }
})
export default router
